<template>
  <RouterView />
  <div class="statuses pos-r z1 pt-20 pb-30">
    <div class="flex ai-c jc-sb ggap-20">
      <h2 class="title">Все статусы</h2>
      <div class="flex ai-c ggap-20">
        <RouterLink to="/settings/statuses/add" class="btn primary">
          <BaseIcon class="ic-16 white" icon="plus-circle" />
          Добавить статус
        </RouterLink>
      </div>
    </div>

    <div class="box mt-20">
      <table class="base-table w-100">
        <thead>
          <th>Название</th>
          <th>Тип</th>
          <th>Цвет текста</th>
          <th>Цвет фона</th>
          <th>Просмотр</th>
        </thead>

        <tbody>
          <Row v-for="row of rows" :key="row.id" :row="row" @deleteAction="deleteAction" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import statuses from '@/api/modules/statuses'
import { useStore } from 'vuex'
import { defineEmits, computed } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit } from '@/plugins'
import Row from './components/Row.vue'

// Emits
const emits = defineEmits(['loadAction'])

// Data
const store = useStore()

// Computed
const rows = computed(() => {
  return store.getters['helpers/statuses']
})

// Created
if (!rows.value?.length) {
  emits('loadAction', true)
  store.dispatch('helpers/GET_STATUSES').finally(() => emits('loadAction', false))
}

// Methods
function deleteAction(id) {
  emits('loadAction', true)
  statuses
    .delete(id)
    .then(() => {
      store.dispatch('helpers/GET_STATUSES').finally(() => {
        emits('loadAction', false)
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: 'Статус успешно удален, востановить его нельзя.'
        })
      })
    })
    .catch((e) => {
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, обратитесь к администратору, или попробуйте еще раз'
      })
    })
    .finally(() => {
      emits('loadAction', false)
    })
}
</script>

<style lang="scss" scoped>
.statuses {
}
</style>
