<template>
  <tr class="statuses__row pos-r" :class="{ active: isDeleteAction, statuses__custom: row.type === 'custom' }">
    <td>
      <b>{{ row.title }}</b>
    </td>
    <td>
      <div class="flex ai-c ggap-5">
        <BaseIcon
          class="ic-16"
          :class="row.type === 'system' ? 'grey' : 'primary'"
          :icon="row.type === 'system' ? 'lock' : 'unlock'"
        />
        {{ row.type === 'system' ? 'Системный' : 'Кастомный' }}
      </div>
    </td>
    <td>{{ row.text_color }}</td>
    <td>{{ row.background_color }}</td>
    <td>
      <small class="marker" :style="{ color: row.text_color, backgroundColor: row.background_color }">
        {{ row.title }}
      </small>
    </td>
    <div class="statuses__actions flex ai-c ggap-5 pr-5">
      <div class="flex ai-c ggap-10" v-if="isDeleteAction">
        <h4 class="title">Вы действительно хотите удалить?</h4>
        <div class="flex ai-c ggap-5">
          <button class="btn sm red pl-10 pr-10" @click.prevent="emits('deleteAction', row.id)">Удалить</button>
          <button class="btn sm cancel pl-10 pr-10" @click.prevent="isDeleteAction = false">Отмена</button>
        </div>
      </div>
      <template v-if="row.type === 'custom' && !isDeleteAction">
        <RouterLink
          :to="'/settings/statuses/edit/' + row.id"
          class="btn sm outline pl-10 pr-10"
          v-tippy="'Редактировать'"
        >
          <BaseIcon class="ic-16 primary" icon="edit" />
        </RouterLink>
        <button
          :disabled="row.type === 'system'"
          class="btn sm outline pl-10 pr-10"
          v-tippy="'Удалить'"
          @click.prevent="deleteToggler"
        >
          <BaseIcon class="ic-16 red" icon="delete" />
          <!-- Удалить -->
        </button>
      </template>
    </div>
  </tr>
</template>

<script setup>
import { defineEmits, defineProps, ref, toRefs } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['deleteAction'])

// Props
const props = defineProps(['row'])

// Data
const { row } = toRefs(props)
const isDeleteAction = ref(false)

// Methods
function deleteToggler() {
  if (row.value.type === 'custom') isDeleteAction.value = !isDeleteAction.value
}
</script>

<style lang="scss" scoped>
.statuses {
  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding-left: 200px;
    background: linear-gradient(to right, transparent, var(--grey) 70%);
  }

  &__custom:hover &__actions,
  &__custom.active &__actions {
    opacity: 1;
  }
  &__custom.active &__actions {
    background: linear-gradient(to right, transparent, var(--grey) 35%);
  }
}
</style>
